/* eslint-disable @typescript-eslint/no-unused-vars */
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';

class AccessControl {

    static testToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJFeGljb20iLCJpYXQiOjE3MTA3NDAwODAsIm5iZiI6MTcxMDc0MDA4MCwiZXhwIjoxNzEwNzQzNjgwLCJzdWIiOiI1NjU4YTU0Zi1lYjI3LTQxNmUtOTY0My1lNWJiNDUxZWRjZDkiLCJhdWQiOiJodHRwczovL2V4aWNvbS1zcGluLXJtcy1hcHAtZGV2LWVudi5henVyZXdlYnNpdGVzLm5ldC8iLCJqdGkiOiJmMDcyYWViZi1iZjExLTRkNzYtODZkZS02M2RiODdmZjhmNzkiLCJzZXNzaW9uSWQiOiJhYWRjZjU5Ny0zYTI5LTQwZWItYTFmMS1mMTMyZmY3Nzc3MDEiLCJncmFudFR5cGUiOiJBY2Nlc3MgdG9rZW4iLCJvcmdJZCI6ImQzZDdlNzg0LTI4OTUtNDU5MC05N2M2LTY4ZDMwODA2NmYwNSIsInJvbGVJZCI6ImJkNWQxMTVmLTlkOGEtNDllZS04ZGRkLTI3MTU5Yzc2ZDdkYyIsInNjb3BlIjoiZGFzaGJvYXJkOiogdXNlcjp2aWV3IG1hbmFnZUNoYXJnZXI6dmlldyJ9.2pLI83PYG858aXmHr5eyDNuTYQqiUwWzQVp7-H3EUT4'
    demoUserId = '40a14b2c-2078-41ab-bf47-271dbc27cda0'
    hasChargerVisibilityEditAccess = () => {
        const token = HWLocalStorage.load('accessToken')
        if (!token) return null
        return JSON.parse(atob(token.split('.')[1])).enableVisibilityEdit === 'Enabled'
    }
    isOrgTypeCustomer = () => {
        const token = HWLocalStorage.load('accessToken')
        if (!token) return null
        return JSON.parse(atob(token.split('.')[1])).orgType === 'Customer'
    }

    static permissions(): string[] | null {
        const token = HWLocalStorage.load('accessToken')
        if (!token) return null
        return JSON.parse(atob(token.split('.')[1])).scope.split(' ');
    }

    fetchRoleId = () => {
        const token = HWLocalStorage.load('accessToken')
        if (!token) return null
        return JSON.parse(atob(token.split('.')[1])).roleId
    }

    isPermitted = (featureScopes) => {
        return (function () {
            try {
                const permissions = AccessControl.permissions();
                if (!featureScopes) { return false; }
                if (!permissions) { return false; }
                const status = featureScopes.some((scope) => permissions.some((featureScope) => featureScope === '!' + scope))
                if (status) { return false }
                if (permissions && (permissions.indexOf('*.*') !== -1)) { return true; }
                return featureScopes.some((scope) => permissions.some((featureScope) => featureScope === scope));
            } catch { return false; }
        });
    }

    // DASHBOARD SCOPES....
    dashboard = {
        view: this.isPermitted(['dashboard:*', 'dashboard:view']),
    }
    // ALARMS SCOPES....
    alarms = {
        view: this.isPermitted(['alarm:*', 'alarm:view']),
        edit: this.isPermitted(['alarm:*', 'alarm:edit']),
        export: this.isPermitted(['alarm:*', 'alarm:export'])
    }

    // MANAGE CHARGERS SCOPES.....
    chargers = {
        view: this.isPermitted(['manageCharger:*', 'manageCharger:view']),
        add: this.isPermitted(['manageCharger:*', 'manageCharger:add']),
        edit: this.isPermitted(['manageCharger:*', 'manageCharger:edit']),
        delete: this.isPermitted(['manageCharger:*', 'manageCharger:delete']),
        export: this.isPermitted(['manageCharger:*', 'manageCharger:export']),
        status: {
            view: this.isPermitted(['manageCharger.status:*', 'manageCharger.status:view'])
        },
        settings: {
            view: this.isPermitted(['manageCharger.setting:*', 'manageCharger.setting:view'])
        },
        systemInfo: {
            view: this.isPermitted(['manageCharger.systemInfo:*', 'manageCharger.systemInfo:view'])
        },
        remoteCommands: {
            view: this.isPermitted(['manageCharger.remoteCommand:*', 'manageCharger.remoteCommand:view'])
        },
        admin: {
            view: this.isPermitted(['manageCharger.admin:*', 'manageCharger.admin:view'])
        }
    }

    //UPDATE SETTINGS OR UPDATE COMMAND SCOPE.....
    updateSetting = {
        view: this.isPermitted(['updateSetting:*', 'updateSetting:view']),
        add: this.isPermitted(['updateSetting:*', 'updateSetting:add']),
        edit: this.isPermitted(['updateSetting:*', 'updateSetting:edit']),
        delete: this.isPermitted(['updateSetting:*', 'updateSetting:delete']),
        export: this.isPermitted(['updateSetting:*', 'updateSetting:export'])
    }

    //SPURIOUS CHARGER SCOPE>.......
    spuriousCharger = {
        view: this.isPermitted(['spuriousCharger:*', 'spuriousCharger:view']),  
    }

    // CHARGING STATIONS SCOPE
    chargingStation = {
        view: this.isPermitted(['chargingStation:*', 'chargingStation:view']),
        add: this.isPermitted(['chargingStation:*', 'chargingStation:add']),
        edit: this.isPermitted(['chargingStation:*', 'chargingStation:edit']),
        delete: this.isPermitted(['chargingStation:*', 'chargingStation:delete']),
        export: this.isPermitted(['chargingStation:*', 'chargingStation:export'])
    }

    // CUSTOMER SCOPES....
    customers = {
        view: this.isPermitted(['customer:*', 'customer:view']),
        add: this.isPermitted(['customer:*', 'customer:add']),
        edit: this.isPermitted(['customer:*', 'customer:edit']),
        delete: this.isPermitted(['customer:*', 'customer:delete']),
        export: this.isPermitted(['customer:*', 'customer:export'])
    }

    // ACCESS CONTROL SCOPES...
    organisation = {
        view: this.isPermitted(['organisation:*', 'organisation:view']),
        add: this.isPermitted(['organisation:*', 'organisation:add']),
        edit: this.isPermitted(['organisation:*', 'organisation:edit']),
        delete: this.isPermitted(['organisation:*', 'organisation:delete']),
        export: this.isPermitted(['organisation:*', 'organisation:export'])
    }

    users = {
        view: this.isPermitted(['user:*', 'user:view']),
        add: this.isPermitted(['user:*', 'user:add']),
        edit: this.isPermitted(['user:*', 'user:edit']),
        delete: this.isPermitted(['user:*', 'user:delete']),
        export: this.isPermitted(['user:*', 'user:export'])
    }

    roles = {
        view: this.isPermitted(['role:*', 'role:view']),
        add: this.isPermitted(['role:*', 'role:add']),
        edit: this.isPermitted(['role:*', 'role:edit']),
        delete: this.isPermitted(['role:*', 'role:delete']),
        export: this.isPermitted(['role:*', 'role:export'])
    }

    // REPORTS SCOPES.....
    reports = {
        chargingHistory: {
            view: this.isPermitted(['report.chargingHistory:*', 'report.chargingHistory:view']),
            export: this.isPermitted(['report.chargingHistory:*', 'report.chargingHistory:export']),
        },
        chargerLog: {
            status: {
                view: this.isPermitted(['report.chargerLog.status:*', 'report.chargerLog.status:view']),
                export: this.isPermitted(['report.chargerLog.status:*', 'report.chargerLog.status:export']),
            },
            setting: {
                view: this.isPermitted(['report.chargerLog.setting:*', 'report.chargerLog.setting:view']),
                export: this.isPermitted(['report.chargerLog.setting:*', 'report.chargerLog.setting:export']),
            },
            systemInfo: {
                view: this.isPermitted(['report.chargerLog.systemInfo:*', 'report.chargerLog.systemInfo:view']),
                export: this.isPermitted(['report.chargerLog.systemInfo:*', 'report.chargerLog.systemInfo:export']),
            }
        },
        exceptionLogs: {
            view: this.isPermitted(['report.exceptionLogs:*', 'report.exceptionLogs:view']),
            export: this.isPermitted(['report.exceptionLogs:*', 'report.exceptionLogs:export'])
        }
    }

    //CONFIGURE NOTIFICATION SCOPES.....
    configureNotification = {
        add: this.isPermitted(['configureNotification:*', 'configureNotification:add']),
        view: this.isPermitted(['configureNotification:*', 'configureNotification:view']),
        edit: this.isPermitted(['configureNotification:*', 'configureNotification:edit']),
        delete: this.isPermitted(['configureNotification:*', 'configureNotification:delete']),
    }


    //RULE ENGINE SCOPES...
    ruleEngine = {
        view: this.isPermitted(['ruleEngine:*', 'ruleEngine:view']),
        add: this.isPermitted(['ruleEngine:*', 'ruleEngine:add']),
        edit: this.isPermitted(['ruleEngine:*', 'ruleEngine:edit']),
        delete: this.isPermitted(['ruleEngine:*', 'ruleEngine:delete']),
    }

    ruleEngineHistory = {
        view: this.isPermitted(['ruleEngineHistory:*', 'ruleEngineHistory:view']),
    }

}

const access = new AccessControl();
export default access;