export const SET_SELECTED_NAV = 'SET_SELECTED_NAV'
export const FETCH_CHARGERS_DATA = 'FETCH_CHARGERS_DATA'
export const SET_CHARGER_LIST_REQUEST_HASH = 'SET_CHARGER_LIST_REQUEST_HASH'
export const SET_SELECTED_CHARGER_DATA = 'SET_SELECTED_CHARGER_DATA'
export const SET_CHARGER_LOADER = 'SET_CHARGER_LOADER'
export const SET_CHARGER_DETAIL_LOADER = 'SET_CHARGER_DETAIL_LOADER'
export const FETCH_CHARGER_DETAIL_DATA = 'FETCH_CHARGER_DETAIL_DATA'
export const FETCH_INDIVIDUAL_CHARGER_DETAIL_DATA = 'FETCH_INDIVIDUAL_CHARGER_DETAIL_DATA'
export const FETCH_SYSTEM_INFO_DATA = 'FETCH_SYSTEM_INFO_DATA'
export const FETCH_MASTER_INFO_DATA = 'FETCH_MASTER_INFO_DATA'
export const FETCH_CHARGER_STATUS_SYSTEM_INFO_DATA = 'FETCH_CHARGER_STATUS_SYSTEM_INFO_DATA'
export const FETCH_CHARGER_STATUS_MASTER_INFO_DATA = 'FETCH_CHARGER_STATUS_MASTER_INFO_DATA'
export const FETCH_CHARGER_CONNECTOR_STATUS_DATA = 'FETCH_CHARGER_CONNECTOR_STATUS_DATA'
export const FETCH_CHARGER_GRAPH_DETAIL = 'FETCH_CHARGER_GRAPH_DETAIL'
export const CLEAR_CHARGER_GRAPH_DETAIL = 'CLEAR_CHARGER_GRAPH_DETAIL'
export const SET_CHARGER_HISTORY_GRAPH_FILTER_HASHES = 'SET_CHARGER_HISTORY_GRAPH_FILTER_HASHES'
export const FETCH_CHARGER_STATUS_LOGS = 'FETCH_CHARGER_STATUS_LOGS'
export const CLEAR_CHARGER_DETAIL = 'CLEAR_CHARGER_DETAIL'
export const FETCH_CHARGER_UPTIME = 'FETCH_CHARGER_UPTIME'
export const SET_CHARGER_HISTORY_DATES = 'SET_CHARGER_HISTORY_DATES'
export const FETCH_CHARGER_SETTINGS_MASTER_INFO_DATA = 'FETCH_CHARGER_SETTINGS_MASTER_INFO_DATA'
export const FETCH_CHARGER_SETTINGS_SYSTEM_INFO_DATA = 'FETCH_CHARGER_SETTINGS_SYSTEM_INFO_DATA'
export const SET_CHARGER_LIST_TABLE_PROPS = 'SET_CHARGER_LIST_TABLE_PROPS'
export const SET_FILTERS_APPLIED_FROM_REDIRECTION = 'SET_FILTERS_APPLIED_FROM_REDIRECTION'
export const SET_ALTERED_MASTER_DATA_FOR_CHARGER_STATUS = 'SET_ALTERED_MASTER_DATA_FOR_CHARGER_STATUS'
export const SET_CHARGER_REDUCER_STATE = 'SET_CHARGER_REDUCER_STATE'
export const CLEAR_CHARGER_LIST_DATA = 'CLEAR_CHARGER_LIST_DATA'

export const FETCH_BROADCAST_MESSAGES = 'FETCH_BROADCAST_MESSAGES'
export const SET_SELECTED_BROADCAST_MESSAGES = 'SET_SELECTED_BROADCAST_MESSAGES'
export const SET_CHARGER_BROADCAST_MESSAGES_TABLE_PROPS = 'SET_CHARGER_BROADCAST_MESSAGES_TABLE_PROPS'
export const SET_BROADCAST_MESASGE_LOADER = 'SET_BROADCAST_MESASGE_LOADER'

export const FETCH_PARAMETERS_DATA = 'FETCH_PARAMETERS_DATA'
export const SET_PARAMETERS_LOADER = 'SET_PARAMETERS_LOADER'
export const SET_CHARGER_PARAMETERS_LIST_TABLE_PROPS = 'SET_CHARGER_PARAMETERS_LIST_TABLE_PROPS'
export const CLEAR_CHARGER_PARAMETER_LIST_DATA = 'CLEAR_CHARGER_PARAMETER_LIST_DATA'



export const FETCH_PARAMETERS_DEVICES_DATA = 'FETCH_PARAMETERS_DEVICES_DATA'
export const SET_PARAMETERS_DEVICES_LOADER = 'SET_PARAMETERS_DEVICES_LOADER'
export const SET_CHARGER_PARAMETERS_DEVICES_LIST_TABLE_PROPS = 'SET_CHARGER_PARAMETERS_DEVICES_LIST_TABLE_PROPS'
export const CLEAR_CHARGER_PARAMETER_DEVICES_LIST_DATA = 'CLEAR_CHARGER_PARAMETER_DEVICES_LIST_DATA'

export const FETCH_PARAMETER_DEVICES_STATUS_COUNT = 'FETCH_PARAMETER_DEVICES_STATUS_COUNT';
export const CLEAR_PARAMETER_DEVICES_STATUS_COUNT = 'CLEAR_PARAMETER_DEVICES_STATUS_COUNT'

export const FETCH_ADD_PARAMETERS_DETAIL_LIST = 'FETCH_ADD_PARAMETERS_DETAIL'
export const FETCH_ADD_PARAMETERS_REF_CHARGING_STATIONS = 'FETCH_ADD_PARAMETERS_REF_CHARGING_STATIONS'
export const FETCH_ADD_PARAMETERS_REF_CHARGERS = 'FETCH_ADD_PARAMETERS_REF_CHARGERS'

export const FETCH_PARAMETERS_SETTINGS_SYSTEM_INFO_DATA = 'FETCH_PARAMETERS_SETTINGS_SYSTEM_INFO_DATA'
export const FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA = 'FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA'
export const SET_PARAMETERS_DETAIL_LOADER = 'SET_PARAMETERS_DETAIL_LOADER'

export const SET_PARAMETERS_TOGGLE_IDS = 'SET_PARAMETERS_TOGGLE_IDS';

export const FETCH_INDIVIDUAL_PARAMETER_DETAIL_DATA = 'FETCH_INDIVIDUAL_PARAMETER_CHARGER_DETAIL_DATA'
export const CLEAR_UPDATED_PARAMETER_DETAIL_DATA = 'CLEAR_UPDATED_PARAMETER_DETAIL_DATA'


export const FETCH_UPDATE_COMMANDS_DATA = 'FETCH_UPDATE_COMMANDS_DATA'
export const SET_UPDATE_COMMANDS_LOADER = 'SET_UPDATE_COMMANDS_LOADER'
export const SET_CHARGER_UPDATE_COMMANDS_LIST_TABLE_PROPS = 'SET_CHARGER_UPDATE_COMMANDS_LIST_TABLE_PROPS'
export const CLEAR_CHARGER_UPDATE_COMMANDS_LIST_DATA = 'CLEAR_CHARGER_UPDATE_COMMANDS_LIST_DATA'

export const FETCH_INDIVIDUAL_UPDATE_COMMAND_DETAIL_DATA= 'FETCH_INDIVIDUAL_UPDATE_COMMAND_DETAIL_DATA'
export const FETCH_UPDATE_COMMAND_DEVICES_STATUS_COUNT = 'FETCH_UPDATE_COMMAND_DEVICES_STATUS_COUNT';
export const FETCH_UPDATE_COMMANDS_DEVICES_DATA = 'FETCH_UPDATE_COMMANDS_DEVICES_DATA'
export const SET_CHARGER_UPDATE_COMMANDS_DEVICES_LIST_TABLE_PROPS='SET_CHARGER_UPDATE_COMMANDS_DEVICES_LIST_TABLE_PROPS'
export const CLEAR_CHARGER_UPDATE_COMMAND_DEVICES_LIST_DATA = 'CLEAR_CHARGER_UPDATE_COMMAND_DEVICES_LIST_DATA'
export const CLEAR_UPDATE_COMMAND_DEVICES_STATUS_COUNT='CLEAR_UPDATE_COMMAND_DEVICES_STATUS_COUNT'


export const FETCH_SPURIOUS_CHARGERS_DATA = 'FETCH_SPURIOUS_CHARGERS_DATA'
export const SET_SPURIOUS_CHARGERS_LOADER = 'SET_SPURIOUS_CHARGERS_LOADER'
export const SET_SPURIOUS_CHARGERS_LIST_TABLE_PROPS = 'SET_SPURIOUS_CHARGERS_LIST_TABLE_PROPS'
export const CLEAR_SPURIOUS_CHARGERS_LIST_DATA = 'CLEAR_SPURIOUS_CHARGERS_LIST_DATA'