
export const configureNotificationDashboardNavList = [
  {
    id: 'configureNotification',
    text: 'Notification Rules',
    isAccessible: true,
  },
  {
    id: 'configureNotificationHistory',
    text: 'History',
    isAccessible: true,
  },
]

export const configureNotificationPopoverNavList = [
  {
    id: 'summary',
    text: 'Summary',
    isAccessible: true
  },
  {
    id: 'history',
    text: 'History',
    isAccessible:true
  },
]

