/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useCallback, useEffect, useState, useMemo, FC } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  Edge,
  EdgeProps,
  ReactFlowInstance,
  getNodesBounds,
  getViewportForBounds,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import Sidebar from './Sidebar';
import { DnDProvider, useDnD } from './dndContext';
import './dndFlow.css';
import { SendNotificationNode, AlarmNode, ElseNode, ParameterNode, PlaceholderNode, RemoteCommandNode, ThenElseNode, ThenNode, WhenNode, ScheduleTimeNode } from '../../componentExports';
import IfThenElseNode from './IfThenElseNode';
import IfThenNode from './IfThenNode';
import CustomEdge from './CustomEdge';
import AddActionNode from './AddActionNode';
import { createBreadCrumbProps, useEffectOnce } from '../../../globalUtils/globalHooks';
import { fetchRuleEngineParamConditionOperatorReferenceData, fetchRuleEngineParamConditionReferenceData, fetchRuleEngineSetParameterParams, fetchTriggerPoitnParameterConditionData, getReferenceDataReducer, getRuleEngineReducer } from '../../../rmsReduxStore/reduxExports';
import { useAppDispatch, useAppSelector } from '../../../rmsReduxStore/rmsReduxHooks';
import RaiseATicketNode from './RaiseATicketNode';
import { CustomBreadCrumbs, CustomButtonGroup, DashboardHeader, DashboardLoader } from '../../../globalUtils/globalExports';
import SetParameterNode from './SetParameter';
import './ExecutionPlan.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { setRuleEngineFormRedux } from '../../../rmsReduxStore/ruleEngineRedux/ruleEngineCreator';
import { IRuleEngineFormRedux } from '../../../rmsReduxStore/ruleEngineRedux/ruleEngineType';
import { cloneDeep, isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image';
import { fetchPreSignedURLForUpload } from '../../../rmsReduxStore/chargersRedux/chargerCreators';
import { IPreSignedUrlInfo } from '../../chargers/AddCharger/AddBulkCharger';
import axios from 'axios';
export interface ExecutionPlanTrigger {
  type: null | string;
  alarmTypeId: null | string;
  parameterId: null | string;
  name?: null | string;
  parameterCondition: null | string;
  parameterConditionName?: null | string;
  parameterValue: string[];
  dataType?: null | string;
  fieldType?: null | string,
  unit?: null | string,
  possibleValue?: null | string,
  errorMessage?: null | string,
  errorMessageSecondary?: null | string,
  parameterType: null | string,

  scheduleType: null | string,
  dateTime: null | string,
  recurrenceDays: null | string[],
  recurrenceRange: null | string,
  recurrenceCount: null | number,
  endDate: null | string,
}
export interface ExecutionPlanCondition {
  index: number;
  type: string;
  alarmTypeId: string;
  parameterId: string;
  parameterName: string;
  parameterCondition: string;
  parameterConditionName?: string;
  parameterValue: string[];
  operator: string;
  parameterType: null | string;
  dataType?: null | string;
  fieldType?: string,
  unit?: string,
  possibleValue?: string,
  errorMessage?: null | string,
  errorMessageSecondary?: null | string,

}
export interface SendNotificationAction {
  type: string;
  notificationType: string[];
  recipients: string[];
  emailContent?: string; /// making this optional for now
  recipientsName?: string[];
}
export interface SetParameters {
  parameterId: string;
  parameterName?: string;
  parameterCondition: string;
  parameterConditionName?: string,
  parameterValue: string[];
  parameterType: null | string,
  parameterOperator?: string,
  fieldType?: string,
  unit?: string,
  possibleValue?: string,
  errorMessage?: null | string,
}
export interface SetParameterAction {
  type: string;
  parameters: SetParameters[];
}
export interface RemoteCommandAction {
  type: string;
  remoteCommandType: string;
  url?: string;
  connectorNumber?: null | number;
}
export interface RaiseTicketAction {
  type: string;
  // subject: string;
  description: string;
  callType: string;
  category: string;
  subCategory: string;
  urgency: string;
}
export type ActionItem =
  SetParameterAction
  | SendNotificationAction
  | RemoteCommandAction
  | RaiseTicketAction;

export interface ExecutionPlanAction {
  flowType: string;
  list: ActionItem[];
}
export interface ExecutionPlan {
  trigger: ExecutionPlanTrigger;
  condition: ExecutionPlanCondition[] | null;
  action: ExecutionPlanAction[];
}
// eslint-disable-next-line no-undef
export interface Nodes {
  selected: boolean;
  id: string,
  position: { x: number, y: number },
  type: string,
  // eslint-disable-next-line no-undef
  /* eslint-disable @typescript-eslint/ban-types */
  data: {},
  measured?: { width: number, height: number }
}

const breadCrumbsFunction = () => {
  const { ruleEngineFormRedux } = useAppSelector(getRuleEngineReducer);
  const location = useLocation();

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'ruleEngineNewRule',
          linkBtnState: { mode: location?.state?.mode, ruleId: location?.state?.ruleId }
        },
        {
          objectType: 'text',
          id: 'text',
          text: 'Define Execution Plan'
        },
        {
          objectType: 'text',
          id: 'text',
          text: ruleEngineFormRedux?.name
        },

      ]
  })
  return breadCrumbs;
}

// eslint-disable-next-line react/prop-types
const DnDFlow = ({ setLevelOfHeirarchy }) => {
  const reactFlowInstance = useReactFlow();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [executionPlan, setExecutionPlan] = useState<ExecutionPlan>({
    trigger: {
      type: '',
      alarmTypeId: '',
      parameterId: null,
      parameterCondition: '',
      parameterValue: [],
      name: null,
      parameterConditionName: null,
      parameterType: null,
      scheduleType: null,
      dateTime: null,
      recurrenceDays: null,
      recurrenceRange: null,
      recurrenceCount: null,
      endDate: null
    },
    condition: null,
    action: [
      {
        flowType: 'If',
        list: [

        ],
      },

    ],
  });
  // const [initialExecutionPlan, setInitialExecutionPlan] = useState<ExecutionPlan>()
  //Inital Node
  const initialNodes = [
    {
      id: '1',
      type: 'placeholder',
      data: {},
      position: { x: 300, y: 300 },
      selected: false
    }
  ]

  const { ruleEngineTriggerPointParameterReferenceData, ruleEngineParamConditionReferenceData, alarmNameReferenceData, ruleEngineSetParameterParam } = useAppSelector(getReferenceDataReducer);
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState<Nodes>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { ruleEngineFormRedux } = useAppSelector(getRuleEngineReducer);
  const [showLoader, setShowLoader] = useState(false);
  const [type] = useDnD();
  const { screenToFlowPosition } = useReactFlow();
  const handleUpdateExecutionPlan = ((data: ExecutionPlan) => {
    setExecutionPlan(data);
  });
  const [refreshAllowed, setRefreshAllowed] = useState(false);
  const location = useLocation();
  const initialExecutionPlanRef = useRef(null)

  //CUTOM NODE TYPE ARRAY (MEMOIZED)
  const nodeTypes =
    useMemo(() => (
      {
        placeholder: PlaceholderNode,
        parameter: ParameterNode,
        alarm: AlarmNode,
        sendNotification: SendNotificationNode,
        addAction: AddActionNode,
        actionControlRaiseATicket: RaiseATicketNode,
        actionControlSetParameter: SetParameterNode,
        actionControlRemoteCommand: RemoteCommandNode,
        thenElse: ThenElseNode,
        whenNode: WhenNode,
        thenNode: ThenNode,
        elseNode: ElseNode,
        scheduler: ScheduleTimeNode,
        ifThenElse: () => <IfThenElseNode label={''}
          executionPlan={executionPlan} handleUpdateExecutionPlan={handleUpdateExecutionPlan} updateEdgesAndNodes={handleIfDelete} />,
        ifThen: () => <IfThenNode label={''}
          executionPlan={executionPlan} handleUpdateExecutionPlan={handleUpdateExecutionPlan} updateEdgesAndNodes={handleIfDelete} />,
      }
    ), [executionPlan, handleUpdateExecutionPlan]);

  //CUSTOM EDGE TYPE ARRAY
  const edgeTypes: { [key: string]: React.FC<any> } = {
    custom: CustomEdge,
  };

  const actionMapping = new Map([
    ['Set Parameter', 'actionControlSetParameter'],
    ['Remote Command', 'actionControlRemoteCommand'],
    ['Send Notification', 'sendNotification'],
    ['Raise Ticket', 'actionControlRaiseATicket'],
  ]);

  useEffectOnce(() => {
    if (ruleEngineFormRedux?.id) {
      dispatch(fetchRuleEngineSetParameterParams());

    }
  })
  const addMissing3SeriesNode = (nodes) => {
    const series3Nodes = nodes.filter(node => /^3[A-Z]+$/.test(node.id));
    if (series3Nodes.length < 4) {
      const nextNodeId = `3${String.fromCharCode(65 + series3Nodes.length)}`;

      const lastNode = series3Nodes[series3Nodes.length - 1];
      const currentNodeId = lastNode?.id;
      const newNode: any = {
        id: nextNodeId,
        type: 'addAction',
        position: {
          x: lastNode?.position?.x,
          y: lastNode?.position.y + 110
        },
        data: {
          addNodeToCanvas: addnodeToCanvasThen,
          id: nextNodeId,
          idToConnect: currentNodeId,
          nodes: nodes
        }
      };
      setNodes((prevNodes) => [...prevNodes, newNode]);
    }
  };
  const addMissing4SeriesNode = (prevNodes) => {
    const series4Nodes = prevNodes.filter(node => /^4[A-Z]+$/.test(node.id));

    // Return prevNodes directly if 4 or more nodes already exist
    if (series4Nodes.length >= 4) {
      return prevNodes;
    }

    // Determine the next node ID in the sequence (e.g., 4A, 4B, 4C...)
    const nextNodeId = `4${String.fromCharCode(65 + series4Nodes.length)}`;

    const lastNode = series4Nodes[series4Nodes.length - 1];
    const currentNodeId = lastNode?.id;

    const newNode: any = {
      id: nextNodeId,
      type: 'addAction',
      position: {
        x: lastNode?.position.x || 0,  // Fallback to 0 if lastNode is undefined
        y: lastNode?.position.y + 110 || 100  // Adjust position by 100, default if no lastNode
      },
      data: {
        addNodeToCanvas: addnodeToCanvasElse,
        id: nextNodeId,
        idToConnect: currentNodeId,
      }
    };

    // Return updated nodes array with the new node added
    return [...prevNodes, newNode];
  };

  let initialActionControlHeight = 468

  const autoPopulateRemainingFields = () => {
    const existingExecutionPlan = cloneDeep(ruleEngineFormRedux?.executionPlan)
    /// parameter and alarm population 
    if (existingExecutionPlan.trigger.type === 'Parameter') {
      const triggerParameterDetails = ruleEngineTriggerPointParameterReferenceData?.find((refItem) => refItem.id === existingExecutionPlan.trigger.parameterId);
      const triggerParameterConditionDetails = ruleEngineParamConditionReferenceData?.find((refItem) => refItem.id === existingExecutionPlan.trigger.parameterCondition)
      existingExecutionPlan.trigger = {
        ...existingExecutionPlan.trigger,
        ...triggerParameterDetails,
        parameterConditionName: triggerParameterConditionDetails?.label,

      }
    }
    else if (existingExecutionPlan.trigger.type === 'Alarm') {
      const triggerAlarmDetails = alarmNameReferenceData?.find((refItem) => refItem.id === existingExecutionPlan.trigger.alarmTypeId)
      existingExecutionPlan.trigger = {
        ...existingExecutionPlan.trigger,
        parameterCondition: '',
        parameterValue: [],
        name: triggerAlarmDetails?.name
      }
    }
    /// condition population 
    existingExecutionPlan.condition = existingExecutionPlan?.condition?.map((reduxItem) => {
      const refData = ruleEngineTriggerPointParameterReferenceData?.find((refItem) => refItem.id === reduxItem.parameterId);
      const parameterConditionData = ruleEngineParamConditionReferenceData?.find((refItem) => refItem.id === reduxItem.parameterCondition);
      return {
        ...reduxItem,
        ...refData,
        parameterName: refData?.label,
        parameterConditionName: parameterConditionData?.label,
        errorMessage: null,
        errorMessageSecondary: null,
      }
    })
    /// checking set parameter in the then section if any set in the existingExecutionPlan value
    const currentSetParameters = (existingExecutionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === 'If')?.list?.find((executionPlanAction) => executionPlanAction.type === 'Set Parameter') as SetParameterAction);
    if (currentSetParameters) {

      const setParameterIndex = existingExecutionPlan?.action?.find((executionDataItem) => executionDataItem.flowType === 'If')?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Set Parameter')
      existingExecutionPlan.action[0].list[setParameterIndex] = {
        type: 'Set Parameter',
        parameters: currentSetParameters.parameters.map((setItem) => {
          const refData = ruleEngineSetParameterParam?.find((refItem) => refItem.id === setItem.parameterId);
          // const parameterConditionData = ruleEngineParamConditionReferenceData?.find((refItem)=> refItem.id === setItem.parameterCondition);
          return {
            ...setItem,
            ...refData,
            type: 'Set Parameter',
            parameterName: refData?.label,
            parameterConditionName: setItem?.parameterCondition === 'equalsTo' ? 'Equals to' : 'is',
            errorMessage: null,
          }
        })
      }
    }
    /// checking set parameter in the else section if any set in the existingExecutionPlan value
    const currentThenSetParameters = (existingExecutionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === 'Else')?.list?.find((executionPlanAction) => executionPlanAction.type === 'Set Parameter') as SetParameterAction);
    if (currentThenSetParameters) {

      const setParameterIndex = existingExecutionPlan?.action?.find((executionDataItem) => executionDataItem.flowType === 'Else')?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Set Parameter')
      existingExecutionPlan.action[1].list[setParameterIndex] = {
        type: 'Set Parameter',
        parameters: currentThenSetParameters.parameters.map((setItem) => {
          const refData = ruleEngineSetParameterParam?.find((refItem) => refItem.id === setItem.parameterId);
          // const parameterConditionData = ruleEngineParamConditionReferenceData?.find((refItem)=> refItem.id === setItem.parameterCondition);
          return {
            ...setItem,
            ...refData,
            type: 'Set Parameter',
            parameterName: refData?.label,
            parameterConditionName: setItem?.parameterCondition === 'equalsTo' ? 'Equals to' : 'is',
            errorMessage: null,
          }
        })
      }
    }
    setExecutionPlan(existingExecutionPlan)
    initialExecutionPlanRef.current = cloneDeep(existingExecutionPlan)
  }
  // useEffectOnce(() => {
  //   const res = autoPopulateRemainingFields()
  //   setInitialExecutionPlan(res)
  // })

  // USE EFFECT TO AUTOPOPULATE THE FIELDS
  useEffect(() => {
    if (ruleEngineFormRedux?.executionPlan) {
      autoPopulateRemainingFields()
    }
  }, [ruleEngineFormRedux])

  //TO HANDLE THE EDIT MODE 
  useEffectOnce(() => {
    dispatch(fetchTriggerPoitnParameterConditionData())
    dispatch(fetchRuleEngineParamConditionReferenceData())
    dispatch(fetchRuleEngineParamConditionOperatorReferenceData())

    if (executionPlan) {
      if (executionPlan?.trigger && executionPlan?.trigger?.type && executionPlan?.trigger?.type.length > 1) {
        const newNode: any = {
          id: '1'.toString(),
          type: executionPlan?.trigger?.type.toLowerCase(),
          position: { x: 418.571, y: 97.931 },
          data: {
            label: '',
            executionPlan: executionPlan,
            handleUpdateExecutionPlan: handleUpdateExecutionPlan,
            delete: handleDeleteEntireTree
          },
        };
        setNodes((nds) =>
          nds.filter((node) => node.id !== '1').concat(newNode));
      }
      if (executionPlan?.condition && executionPlan?.condition?.length > 0) {
        const newNode: any = {
          id: '2',
          type: 'ifThenElse',
          position: { x: 316, y: 297 },
          data: {
            label: '',
            executionPlan: executionPlan,
            handleUpdateExecutionPlan: handleUpdateExecutionPlan,
            updateEdgesAndNodes: handleIfDelete
          },
        };
        setNodes((nds) =>
          nds
            .filter((node) => node.id !== '2')
            .concat(newNode));
      }
      if (executionPlan?.action[0]?.flowType === 'If' && executionPlan?.action[0]?.list.length > 0) {
        const newNodeThen: any = {
          id: '3',
          type: 'thenNode',
          data: {},
          position: { x: 377, y: 450 },
        };

        const newNodesArray: any = [];
        if (executionPlan?.action[0]?.flowType === 'If' && executionPlan?.action[0]?.list.length) {
          executionPlan.action[0].list.forEach((item, index) => {
            const nodeId = `3${String.fromCharCode(65 + index)}`;
            const newNode: any = {
              id: nodeId,
              type: item?.type?.length > 1 ? actionMapping.get(item?.type) : '',
              data: {
                actionButtonVisible: '',
                addNodeToCanvas: undefined,
                addIcon: false,
                executionPlan: executionPlan,
                handleUpdateExecutionPlan: handleUpdateExecutionPlan,
                ifOrElse: 'If',
                removeNode: handleRemoveNode,
                id: nodeId,
                typeOfNode: item?.type?.length > 1 ? actionMapping.get(item?.type) : '',
              },
              position: { x: 556, y: initialActionControlHeight + index * 220 },
            }

            newNodesArray.push(newNode);
          })
          addMissing3SeriesNode(newNodesArray)
          initialActionControlHeight = initialActionControlHeight * (executionPlan?.action[0]?.list.length / 1.3)

        }

        setNodes((prevNodes) => [...prevNodes, newNodeThen, ...newNodesArray]);
      }
      if (executionPlan?.action[1]?.flowType === 'Else' && executionPlan?.action[1]?.list.length > 0) {
        const newNodeElse: any = {
          id: '4',
          type: 'elseNode',
          data: {},
          position: { x: 375, y: initialActionControlHeight + 200 },
        };

        const newNodesArray: any = [];
        if (executionPlan?.action[1]?.flowType === 'Else') {

          executionPlan.action[1].list.forEach((item, index) => {

            const nodeId = `4${String.fromCharCode(65 + index)}`;
            const newNode: any = {
              id: nodeId,
              type: item?.type?.length > 1 ? actionMapping.get(item?.type) : '',
              data: {
                actionButtonVisible: '',
                addNodeToCanvas: undefined,
                addIcon: false,
                executionPlan: executionPlan,
                handleUpdateExecutionPlan: handleUpdateExecutionPlan,
                ifOrElse: 'Else',
                removeNode: handleRemoveNode,
                id: nodeId,
                typeOfNode: item?.type?.length > 1 ? actionMapping.get(item?.type) : '',
              },
              position: { x: 556, y: (initialActionControlHeight + 300) + (index) * 220 },
            };
            newNodesArray.push(newNode);
          });
        }

        const finalNodes: any = addMissing4SeriesNode(newNodesArray)
        setNodes((prevNodes) => [...prevNodes, ...finalNodes, newNodeElse]);
      }
      const edgesArray: any[] = [];
      // const nodesMap = new Map(nodes.map((node) => [node.id, node]));
      // console.log(nodesMap,'NODES MAP')

      // if (nodesMap.has('1') && nodesMap.has('2')) {
      //   edgesArray.push({
      //     id: 'edge-1-2',
      //     source: '1',
      //     target: '2',
      //     type: 'custom',
      //     markerEnd: { type: 'arrowclosed' },
      //   });
      // } else if (nodesMap.has('1') && nodesMap.has('3')) {
      //   edgesArray.push({
      //     id: 'edge-1-3',
      //     source: '1',
      //     target: '3',
      //     type: 'custom',
      //     markerEnd: { type: 'arrowclosed' },
      //   });
      // }

      // edgesArray.push(
      //   // { id: 'edge-1-2', source: '1', target: '2', type: 'custom', markerEnd: { type: 'arrowclosed' } },
      //   { id: 'edge-2-3', source: '2', target: '3', type: 'custom', markerEnd: { type: 'arrowclosed' } },
      //   { id: 'edge-2-4', source: '2', target: '4', type: 'custom', markerEnd: { type: 'arrowclosed' } }
      // );


      // Add dynamic edges for action nodes (3 -> 3A, 3 -> 3B, etc.)
      executionPlan?.action[0]?.list.forEach((_, index) => {
        const sourceId = '3';
        const targetId = `3${String.fromCharCode(65 + index)}`;  // Generates 3A, 3B, etc.
        edgesArray.push({
          id: `edge-${sourceId}-${targetId}`,
          source: sourceId,
          target: targetId,
          type: 'custom',
          targetHandle: 'left',
          markerEnd: { type: 'arrowclosed' },
        });
      });

      // Add dynamic edges for Else nodes (4 -> 4A, 4 -> 4B, etc.)
      executionPlan?.action[1]?.list.forEach((_, index) => {
        const sourceId = '4';
        const targetId = `4${String.fromCharCode(65 + index)}`;  // Generates 4A, 4B, etc.
        edgesArray.push({
          id: `edge-${sourceId}-${targetId}`,
          source: sourceId,
          target: targetId,
          type: 'custom',
          targetHandle: 'left',
          markerEnd: { type: 'arrowclosed' },
        });
      });
      setNodes((prevNodes) => {
        const newNodes = [...prevNodes, /* new nodes */]; // Add new nodes
        addNodesAndEdges(newNodes, edgesArray); // Call the function to handle edges
        return newNodes; // Return the updated nodes array
      });
      // setEdges((prevEdges) => [...prevEdges, ...edgesArray]);

    }
  })
  const addNodesAndEdges = (newNodes, edgesArray) => {
    const nodesMap = new Map(newNodes.map((node) => [node.id, node]));


    if (nodesMap.has('1') && nodesMap.has('2')) {
      edgesArray.push({
        id: 'edge-1-2',
        source: '1',
        target: '2',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      });
    } else if (nodesMap.has('1') && nodesMap.has('3')) {
      edgesArray.push({
        id: 'edge-1-3',
        source: '1',
        target: '3',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      });
    }

    if (nodesMap.has('2') && nodesMap.has('3')) {
      edgesArray.push({
        id: 'edge-2-3',
        source: '2',
        target: '3',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      });
    }

    if (nodesMap.has('2') && nodesMap.has('4')) {
      edgesArray.push({
        id: 'edge-2-4',
        source: '2',
        target: '4',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      });
    }

    setNodes(newNodes);
    setEdges(edgesArray);
  };

  //MAIN USEEFFECT TO RENDER NEW NODES ACCORDING TO DIFFERENT CONDITIONS
  useEffect(() => {
    if (nodes.length == 0) {

      const newNode: any = {
        id: '1',
        type: 'placeholder',
        data: {},
        position: { x: 300, y: 300 },
      };

      setNodes((nds) =>
        nds.concat(newNode));
    }
    if (nodes.length === 1 && (nodes[0]?.type === 'parameter' || nodes[0]?.type === 'alarm' || nodes[0]?.type === 'scheduler')) {
      const newNodePlaceholder = {
        id: '2',
        type: 'placeholder',
        data: { type: 'placeholder', size: 'small' },
        position: { x: 393.5, y: 310 },
        selected: false
      };
      setNodes((nds) => [...nds,
        // newNode,
        newNodePlaceholder
      ]);

      const newEdge = {
        id: 'edge-1-2',
        source: '1',
        target: '2',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      };

      setEdges((eds: any) => [...eds, newEdge]);
    }

    if (nodes.length === 2 && nodes[1].type == 'ifThenElse') {
      const newNodeThen: any = {
        id: '3',
        type: 'thenNode',
        data: {},
        position: { x: 377, y: 453 },
      };
      const newNodeElse: any = {
        id: '4',
        type: 'elseNode',
        data: {},
        position: { x: 375, y: 709 },
      };
      const newNodeThenPlaceholder: any = {
        id: '3A',
        type: 'placeholder',
        data: { size: 'small' },
        position: { x: 556, y: 415 },
      };
      const newNodeElsePlaceholder: any = {
        id: '4A',
        type: 'placeholder',
        data: { size: 'small' },
        position: {
          x: 556, y: 671
        },
      };

      setNodes((nds) =>
        nds.concat(newNodeThen, newNodeThenPlaceholder, newNodeElse, newNodeElsePlaceholder));

      const newEdgeThen = {
        id: 'edge-2-3',
        source: '2',
        target: '3',
        type: 'custom',
        // data: {label:'Then'},
        markerEnd: { type: 'arrowclosed' },

      };
      const newEdgeThenPlaceholder = {
        id: 'edge-3-3A',
        source: '3',
        target: '3A',
        type: 'custom',
        // data: {label:'Then'},
        markerEnd: { type: 'arrowclosed' },
        targetHandle: 'left',
        sourceHandle: 'right'
      };
      const newEdgeElse = {
        id: 'edge-2-4',
        source: '2',
        target: '4',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
      };
      const newEdgeElsePlaceholder = {
        id: 'edge-4-4A',
        source: '4',
        target: '4A',
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
        targetHandle: 'left',
        sourceHandle: 'right'
      };
      setEdges((eds: any) => [...eds, newEdgeThen, newEdgeThenPlaceholder, newEdgeElse, newEdgeElsePlaceholder]);
    }
    if (nodes.length === 2 && nodes[1].type == 'ifThen') {
      const newNodeThen: any = {
        id: '3',
        type: 'thenNode',
        data: {},
        position: { x: 377, y: 453 },
      };
      const newNodeThenPlaceholder: any = {
        id: '3A',
        type: 'placeholder',
        data: { size: 'small' },
        position: { x: 556, y: 415 },

      };
      const newEdgeThen = {
        id: 'edge-2-3',
        source: '2',
        target: '3',
        type: 'custom',
        // data: {label:'Then'},
        markerEnd: { type: 'arrowclosed' },

      };
      const newEdgeThenPlaceholder = {
        id: 'edge-3-3A',
        source: '3',
        target: '3A',
        type: 'custom',
        // data: {label:'Then'},
        markerEnd: { type: 'arrowclosed' },
        targetHandle: 'left',
        sourceHandle: 'right'
      };

      setNodes((nds) =>
        nds.concat(newNodeThen, newNodeThenPlaceholder));

      setEdges((eds: any) => [...eds, newEdgeThen, newEdgeThenPlaceholder]);
    }

  }, [nodes, edges])

  //DEFAULT REACT-FLOW FUNCTION
  const onConnect = useCallback(
    (params) =>
      setEdges((eds: any[]) =>
        addEdge({ ...params, markerEnd: { type: 'arrowclosed' } }, eds)
      ),
    []
  );

  //HELPER FUNCTION TO HANDLE WHEN CROSS ICON ON TRIGGER POINT NODE IS CLICKED
  const handleDeleteEntireTree = () => {
    setLevelOfHeirarchy('trigger')
    setNodes([])
    setEdges([])
    const updatedExecutionPlan = cloneDeep(executionPlan);
    updatedExecutionPlan.trigger = {
      type: '',
      alarmTypeId: '',
      parameterId: '',
      parameterCondition: '',
      parameterValue: [],
      name: null,
      parameterConditionName: null,
      parameterType: null,
      scheduleType: null,
      dateTime: null,
      recurrenceDays: null,
      recurrenceRange: null,
      recurrenceCount: null,
      endDate: null
    };
    updatedExecutionPlan.condition = null;
    updatedExecutionPlan.action = [
      {
        flowType: 'If',
        list: [

        ],
      }

    ];
    handleUpdateExecutionPlan(updatedExecutionPlan);

  }

  //HELPER FUNCTION TO HANDLE WHEN CROSS ICON ON IF NODE IS CLICKED
  const handleIfDelete = () => {
    setLevelOfHeirarchy('flow')
    const filteredNodes = nodes.filter(node => {
      if (node && node.id) {

        const firstChar = node.id.charAt(0);
        const nodeIdNumber = parseInt(firstChar, 10);
        return !isNaN(nodeIdNumber) && nodeIdNumber <= 1;
      }
      return false;
    });
    const filteredEdges = edges.filter(edge => {
      const sourceId = edge?.source?.charAt(0);
      const targetId = edge?.target?.charAt(0);
      const sourceNum = parseInt(sourceId, 10);
      const targetNum = parseInt(targetId, 10);
      return (!isNaN(sourceNum) && sourceNum !== 2 && sourceNum !== 3 && targetNum !== 3)
    });

    const newNode: any = {
      id: '2',
      type: 'placeholder',
      data: { type: 'placeholder', size: 'small' },
      position: { x: 393, y: 385 }
    }

    const updatedNodes = [...filteredNodes, newNode];
    const updatedEdges = [...filteredEdges];

    setNodes(updatedNodes)
    setEdges(updatedEdges)
    const updatedExecutionPlan = cloneDeep(executionPlan);
    updatedExecutionPlan.condition = [];
    updatedExecutionPlan.action = [
      {
        flowType: 'If',
        list: [
        ],
      }

    ];
    handleUpdateExecutionPlan(updatedExecutionPlan);
  }

  //DEFAULT REACT-FLOW FUNCTION
  const handleNodeDragStop = (event, node) => {
    setNodes((nds) =>
      nds.map((n) => (n.id === node.id ? { ...n, position: node.position } : n))
    );
  };

  //DEFAULT REACT-FLOW FUNCTION
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  //USEEFFECT FOR UPDATING EXECUTIONPLAN INSIDE POPOVERS
  useEffect(() => {
    setNodes((prevNodes) => {
      // Process nodes once
      return prevNodes.map((node) => {
        // Clone the node for modifications
        let updatedNode = { ...node };

        // Condition 1: Update execution plan data for specific nodes
        if (/^1[A-Z]*$/.test(node.id) || /^2[A-Z]*$/.test(node.id) || /^3[A-Z]*$/.test(node.id) || /^4[A-Z]*$/.test(node.id)) {
          updatedNode = {
            ...updatedNode,
            data: {
              ...updatedNode.data,
              executionPlan,
              handleUpdateExecutionPlan,
            },
          };
        }
        return updatedNode;
      });
    });

  }, [executionPlan]);

  const setParameterIndexThenInfoStackLength = executionPlan?.action[0]?.list
    .find((li: ActionItem): li is SetParameterAction => li.type === 'Set Parameter')
    ?.parameters?.length
  const setParameterIndexThenInfoStackLengthFinal = setParameterIndexThenInfoStackLength ? setParameterIndexThenInfoStackLength - 1 : 0
  const stackDifferenceIf = executionPlan.condition && executionPlan?.condition?.length - 1 || 0;
  const setParameterIndexElseInfoStackLength = executionPlan?.action[1]?.list
    .find((li: ActionItem): li is SetParameterAction => li.type === 'Set Parameter')
    ?.parameters?.length
  const setParameterIndexElseInfoStackLengthFinal = setParameterIndexElseInfoStackLength ? setParameterIndexElseInfoStackLength - 1 : 0

  //DOWNSHIFTING NODES ON ADDITION OF MULTIPLE CONDTIONS IN ***IF*****
  useEffect(() => {
    const downShiftPerStackDifference = 20;

    setNodes((prevNodes) => {
      // Process nodes once
      return prevNodes.map((node) => {
        let updatedNode = { ...node };

        const nodeLevel = parseInt(node.id.match(/^\d+/)?.[0] || '0', 10);
        if ((nodeLevel === 3 || nodeLevel === 4) && stackDifferenceIf > 0) {
          updatedNode = {
            ...updatedNode,
            position: {
              x: updatedNode.position.x,
              y: updatedNode.position.y + downShiftPerStackDifference * stackDifferenceIf,
            },
          };
        }
        return updatedNode;
      });
    });

  }, [stackDifferenceIf])

  //DOWNSHIFTING NODES ON ADDITION OF MULTIPLE CONDTIONS IN ***THEN*****
  useEffect(() => {
    const setParameterIndexThen = nodes.filter(nds => nds.type == 'actionControlSetParameter' && nds.id.startsWith('3'))[0]?.id
    const downShiftPerStackDifference = 20;

    setNodes((prevNodes) => {

      // Process nodes once
      return prevNodes.map((node) => {
        let updatedNode = { ...node };
        if (setParameterIndexThen) {
          const affectedNodes = ['3A', '3B', '3C', '3D', '4A', '4B', '4C', '4D'];
          const startIndex = affectedNodes.indexOf(setParameterIndexThen);

          // If the current node is in the series and comes after the starting node
          if (startIndex !== -1 && affectedNodes.indexOf(node.id) > startIndex) {
            updatedNode = {
              ...updatedNode,
              position: {
                x: updatedNode.position.x,
                y: updatedNode.position.y + downShiftPerStackDifference * setParameterIndexThenInfoStackLengthFinal,
              },
            };
          }
        }

        return updatedNode;
      });
    });
  }, [setParameterIndexThenInfoStackLengthFinal])

  //DOWNSHIFTING NODES ON ADDITION OF MULTIPLE CONDTIONS IN ***ELSE*****
  useEffect(() => {
    const setParameterIndexElse = nodes.filter(nds => nds.type == 'actionControlSetParameter' && nds.id.startsWith('4'))[0]?.id
    const downShiftPerStackDifference = 20;
    setNodes((prevNodes) => {

      // Process nodes once
      return prevNodes.map((node) => {
        let updatedNode = { ...node };

        if (setParameterIndexElse) {
          const affectedNodes = ['4A', '4B', '4C', '4D'];
          const startIndex = affectedNodes.indexOf(setParameterIndexElse);

          // If the current node is in the series and comes after the starting node
          if (startIndex !== -1 && affectedNodes.indexOf(node.id) > startIndex) {
            updatedNode = {
              ...updatedNode,
              position: {
                x: updatedNode.position.x,
                y: updatedNode.position.y + downShiftPerStackDifference * setParameterIndexElseInfoStackLengthFinal,
              },
            };
          }
        }
        return updatedNode;
      });
    });

  }, [setParameterIndexElseInfoStackLengthFinal])

  //HELPER FUNCTION TO REMOVE THE NODE AND RENDER A PLACEHOLDER IN ITS PLACE
  const handleRemoveNode = (nodeId: string, ifOrElse: string, typeOfNode: string) => {

    // const currentData = cloneDeep(executionPlan);
    // console.log(currentData,nodeId,ifOrElse,typeOfNode,'check01');
    // if (currentData?.action?.[ifOrElse === 'If' ? 0 : 1]) {
    //   currentData.action[ifOrElse === 'If' ? 0 : 1].list = 
    //     currentData.action[ifOrElse === 'If' ? 0 : 1].list?.filter(
    //       (item) => {
    //         console.log((item.type),typeOfNode,'check02')
    //         return (actionMapping.get(item.type) !== typeOfNode)
    //       }
    //     );
    // }
    // setExecutionPlan(currentData);

    setExecutionPlan((prev) => {
      if (prev?.action?.[ifOrElse === 'If' ? 0 : 1]) {
        prev.action[ifOrElse === 'If' ? 0 : 1].list =
          prev.action[ifOrElse === 'If' ? 0 : 1].list?.filter(
            (item) => {
              return (actionMapping.get(item.type) !== typeOfNode)
            }
          );
      }
      return prev;
    })


    setNodes((prevNodes) => {

      const nodeToRemove = prevNodes.find((node) => node.id === nodeId);
      if (!nodeToRemove) return prevNodes;

      // Determine the series and next node ID (e.g., from '3A' to '3B').
      const seriesNumber = nodeId[0];   // Extract series (e.g., '3' from '3A')
      const currentLetter = nodeId[1];  // Extract letter (e.g., 'A' from '3A')
      const nextLetter = String.fromCharCode(currentLetter.charCodeAt(0) + 1);  // Get next letter (e.g., 'B')
      const nextNodeId = `${seriesNumber}${nextLetter}`;  // Construct next node ID (e.g., '3B')

      // Check if the next node exists and is of type 'addAction'.
      const nextNode = prevNodes.find((node) => node.id === nextNodeId);

      // Create a placeholder node for the current node.
      const placeholderNode = {
        id: nodeId,
        type: 'placeholder',
        position: nodeToRemove.position,
        data: { label: 'Placeholder', size: 'small' },
        selected: false,
      };

      // Remove the current node and conditionally the next 'addAction' node.
      return prevNodes
        .filter((node) => node.id !== nodeId && (node.id !== nextNodeId || nextNode?.type !== 'addAction'))  // Remove the next node only if it's 'addAction'
        .concat(placeholderNode);  // Replace the current node with the placeholder
    });
  };
  //HELPER FUNCTION TO CHECK DUPLICATE IN 3 OR 4 SERIES
  const checkDuplicateInSeries = (seriesPrefix, type) => {
    return nodes.some(node => node.id.startsWith(seriesPrefix) && node.type === type);
  };
  // Function to get the preceding node ID based on your specific logic (e.g., based on position, connections)
  const getPrecedingNodeId = (nodeId) => {
    const nodeSeries = nodeId.charAt(0); // Get the series (e.g., '3' or '4')
    const nodeIndex = parseInt(nodeId.charAt(1), 10); // Get the index (e.g., 'A', 'B', 'C', 'D')

    // Logic to determine the preceding node ID
    if (nodeIndex === 1) return `${nodeSeries}A`; // For 3B or 4B, the preceding node would be 3A or 4A
    if (nodeIndex === 2) return `${nodeSeries}B`; // For 3C or 4C, the preceding node would be 3B or 4B
    if (nodeIndex === 3) return `${nodeSeries}C`; // For 3D or 4D, the preceding node would be 3C or 4C

    return null; // In case the node doesn't have a preceding node
  };

  //FUNCTION HANDLING ALL ONDROP'S
  const onDrop = useCallback(

    (event) => {
      event.preventDefault();
      const targetNode2 = nodes.find((node) => node.id === '2');

      const targetNode3A = nodes.find((node) => node.id === '3A');
      const targetNode3B = nodes.find((node) => node.id === '3B');
      const targetNode3C = nodes.find((node) => node.id === '3C');
      const targetNode3D = nodes.find((node) => node.id === '3D');

      const targetNode4A = nodes.find((node) => node.id === '4A');
      const targetNode4B = nodes.find((node) => node.id === '4B');
      const targetNode4C = nodes.find((node) => node.id === '4C');
      const targetNode4D = nodes.find((node) => node.id === '4D');

      if (!type) {
        return;
      }

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });
      if ((type.toString() == 'parameter' || type.toString() == 'alarm' || type.toString() == 'scheduler') && position.x > nodes[0].position.x && position.x < nodes[0].position.x + 450 && position.y > nodes[0].position.y && position.y < nodes[0].position.y + 450) {
        setLevelOfHeirarchy('flow')

        const newNode: any = {
          id: '1'.toString(),
          type,
          position: { x: 418.571, y: 97.931 },
          data: {
            label: `${type} node`,
            executionPlan: executionPlan,
            handleUpdateExecutionPlan: handleUpdateExecutionPlan,
            delete: handleDeleteEntireTree
          },
        };

        setNodes((nds) =>
          nds.filter((node) => node.id !== '1')
            .concat(newNode));
      }
      else if (type.toString() == 'ifThenElse' && targetNode2?.type == 'placeholder' && position.x > targetNode2.position.x && position.x < targetNode2.position.x + 350 && position.y > targetNode2.position.y && position.y < targetNode2.position.y + 130) {
        setLevelOfHeirarchy('action')
        setExecutionPlan((prevPlan) => {
          // Create a shallow copy of the previous executionPlan
          const updatedPlan = { ...prevPlan };

          // Ensure `condition` exists and is an array
          if (!updatedPlan.condition) {
            updatedPlan.condition = [];
          }

          // Add the new flowType object to the `action` array
          const newAction = {
            flowType: 'Else',
            list: [],
          };
          const updatedAction = [...updatedPlan.action, newAction];

          // Return the updated executionPlan
          return { ...updatedPlan, action: updatedAction };
        });
        const newNode: any = {
          id: '2',
          type,
          position: { x: 316, y: 297 },
          data: {
            label: `${type} node`,
            executionPlan: executionPlan,
            handleUpdateExecutionPlan: handleUpdateExecutionPlan,
            updateEdgesAndNodes: handleIfDelete
          },
        };
        setNodes((nds) =>
          nds
            .filter((node) => node.id !== '2')
            .concat(newNode));
      }

      else if (type.toString() == 'ifThen' && targetNode2?.type == 'placeholder' && position.x > targetNode2.position.x && position.x < targetNode2.position.x + 350 && position.y > targetNode2.position.y && position.y < targetNode2.position.y + 130) {
        setLevelOfHeirarchy('action')
        setExecutionPlan((prevPlan) => {
          const updatedPlan = { ...prevPlan };
          if (!updatedPlan.condition) {
            updatedPlan.condition = [];
          }
          return { ...updatedPlan };
        });
        const newNode: any = {
          id: '2',
          type,
          position: { x: 317, y: 302 },
          data: {
            label: `${type} node`,
            executionPlan: executionPlan,
            handleUpdateExecutionPlan: handleUpdateExecutionPlan,
            updateEdgesAndNodes: handleIfDelete
          },
        };

        const newEdgeThen = {
          id: 'edge-1-2',
          source: '1',
          target: '2',
          type: '',
          markerEnd: { type: 'arrowclosed' },
        };

        setNodes((nds) =>
          nds
            .filter((node) => node.id !== '2')
            .concat(newNode));
        setEdges((eds: any) => [...eds, newEdgeThen]);

      }

      else if ((type.toString() == 'then') && targetNode2?.type == 'placeholder' && position.x > targetNode2.position.x && position.x < targetNode2.position.x + 350 && position.y > targetNode2.position.y && position.y < targetNode2.position.y + 130) {
        setLevelOfHeirarchy('action')
        const newNodeThen: any = {
          id: '3',
          type: 'thenNode',
          data: {},
          position: { x: 349, y: 336 },
        };
        const newNodeThenPlaceholder: any = {
          id: '3A',
          type: 'placeholder',
          data: { size: 'small' },
          position: { x: 541, y: 298 },
        };
        const newEdgeThen = {
          id: 'edge-1-3',
          source: '1',
          target: '3',
          type: 'custom',
          markerEnd: { type: 'arrowclosed' },
          sourceHandle: 'left'
        };

        const newEdgeThenPlaceholder = {
          id: 'edge-3-3A',
          source: '3',
          target: '3A',
          type: 'custom',
          markerEnd: { type: 'arrowclosed' },
          targetHandle: 'left',
          sourceHandle: 'right'
        };
        setNodes((nds) =>
          nds.filter((node) => node.id !== '2').
            concat(newNodeThen, newNodeThenPlaceholder));
        setEdges((eds: any) => [...eds, newEdgeThen, newEdgeThenPlaceholder]);

      }
      else if ((type.toString() == 'sendNotification') || (type.toString() == 'actionControlRaiseATicket') || (type.toString() == 'actionControlSetParameter') || (type.toString() == 'actionControlRemoteCommand')) {
        setLevelOfHeirarchy('action')
        if (targetNode3A && targetNode3A.type == 'placeholder' && position.x > targetNode3A.position.x && position.x < targetNode3A.position.x + 350 && position.y > targetNode3A.position.y && position.y < targetNode3A.position.y + 130) {
          if (checkDuplicateInSeries('3', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '3A',
            type,
            position: { x: targetNode3A.position.x, y: targetNode3A.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              addIcon: false,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'If',
              removeNode: handleRemoveNode,
              id: '3A',
              typeOfNode: type
            },
          }

          const newNodeAddAction: any = {
            id: '3B',
            type: 'addAction',
            position: { x: targetNode3A.position.x, y: targetNode3A.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasThen,
              id: '3B',
              idToConnect: '3A'
            },
          };

          addAndShiftNode('3A', newNode, newNodeAddAction, true)
        }
        else if (targetNode3B && targetNode3B.type == 'placeholder' && position.x > targetNode3B.position.x && position.x < targetNode3B.position.x + 350 && position.y > targetNode3B?.position.y && position.y < targetNode3B?.position.y + 130) {
          if (checkDuplicateInSeries('3', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '3B',
            type,
            position: { x: targetNode3B.position.x, y: targetNode3B.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'If',
              removeNode: handleRemoveNode,
              id: '3B',
              typeOfNode: type
            }
          }
          const newNodeAddAction2: any = {
            id: '3C',
            type: 'addAction',
            position: { x: targetNode3B.position.x, y: targetNode3B.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasThen,
              id: '3C',
              idToConnect: '3B'
            },
          };
          addAndShiftNode('3B', newNode, newNodeAddAction2, true)
        }
        else if (targetNode3C && targetNode3C.type == 'placeholder' && position.x > targetNode3C.position.x && position.x < targetNode3C.position.x + 350 && position.y > targetNode3C?.position.y && position.y < targetNode3C?.position.y + 200) {
          if (checkDuplicateInSeries('3', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '3C',
            type,
            position: { x: targetNode3C.position.x, y: targetNode3C.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'If',
              removeNode: handleRemoveNode,
              id: '3C',
              typeOfNode: type

            },
          }
          const newNodeAddAction2: any = {
            id: '3D',
            type: 'addAction',
            position: { x: targetNode3C.position.x, y: targetNode3C.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasThen,
              id: '3D',
              idToConnect: '3C'
            },
          };


          addAndShiftNode('3C', newNode, newNodeAddAction2, true)
        }
        else if (targetNode3D && targetNode3D.type == 'placeholder' && position.x > targetNode3D.position.x && position.x < targetNode3D.position.x + 350 && position.y > targetNode3D?.position.y && position.y < targetNode3D?.position.y + 200) {
          if (checkDuplicateInSeries('3', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '3D',
            type,
            position: { x: targetNode3D.position.x, y: targetNode3D.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              // addIcon: true,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'If',
              removeNode: handleRemoveNode,
              id: '3D',
              typeOfNode: type
            },
          }

          addAndShiftNode('3D', newNode, null, true)
        }
        else if (targetNode4A && targetNode4A.type == 'placeholder' && position.x > targetNode4A.position.x && position.x < targetNode4A.position.x + 350 && position.y > targetNode4A.position.y && position.y < targetNode4A.position.y + 200) {
          if (checkDuplicateInSeries('4', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '4A',
            type,
            position: { x: targetNode4A.position.x, y: targetNode4A.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              addIcon: false,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'Else',
              removeNode: handleRemoveNode,
              id: '4A',
              typeOfNode: type
            },
          };
          const newNodeAddAction: any = {
            id: '4B',
            type: 'addAction',
            position: { x: targetNode4A.position.x, y: targetNode4A.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasElse,
              id: '4B',
              idToConnect: '4A'

            },

          };
          addAndShiftNode('4A', newNode, newNodeAddAction, true)

        }
        else if (targetNode4B && targetNode4B.type == 'placeholder' && position.x > targetNode4B.position.x && position.x < targetNode4B.position.x + 350 && position.y > targetNode4B.position.y && position.y < targetNode4B.position.y + 200) {
          if (checkDuplicateInSeries('4', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '4B',
            type,
            position: { x: targetNode4B.position.x, y: targetNode4B.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'Else',
              removeNode: handleRemoveNode,
              id: '4B',
              typeOfNode: type

            },
          };
          const newNodeAddAction2: any = {
            id: '4C',
            type: 'addAction',
            position: { x: targetNode4B.position.x, y: targetNode4B.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasElse,
              id: '4C',
              idToConnect: '4B'
            },
          };

          addAndShiftNode('4B', newNode, newNodeAddAction2, true)
        }
        else if (targetNode4C && targetNode4C.type == 'placeholder' && position.x > targetNode4C.position.x && position.x < targetNode4C.position.x + 350 && position.y > targetNode4C?.position.y && position.y < targetNode4C?.position.y + 350) {
          if (checkDuplicateInSeries('4', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '4C',
            type,
            position: { x: targetNode4C.position.x, y: targetNode4C.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              // addIcon: true,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'Else',
              removeNode: handleRemoveNode,
              id: '4C',
              typeOfNode: type
            },

          }
          const newNodeAddAction2: any = {
            id: '4D',
            type: 'addAction',
            position: { x: targetNode4C.position.x, y: targetNode4C.position.y + 110 },
            data: {
              addNodeToCanvas: addnodeToCanvasElse,
              id: '4D',
              idToConnect: '4C',

            },
          };
          addAndShiftNode('4C', newNode, newNodeAddAction2, true)
        }
        else if (targetNode4D && targetNode4D.type == 'placeholder' && position.x > targetNode4D.position.x && position.x < targetNode4D.position.x + 200 && position.y > targetNode4D?.position.y && position.y < targetNode4D?.position.y + 200) {
          if (checkDuplicateInSeries('4', type)) {
            toast.error('Cannot have duplicate controls in a single flow.');
            return;
          }
          const newNode: any = {
            id: '4D',
            type,
            position: { x: targetNode4D.position.x, y: targetNode4D.position.y },
            data: {
              actionButtonVisible: '',
              addNodeToCanvas: undefined,
              executionPlan: executionPlan,
              handleUpdateExecutionPlan: handleUpdateExecutionPlan,
              ifOrElse: 'Else',
              removeNode: handleRemoveNode,
              id: '4D',
              typeOfNode: type
            },
          }
          addAndShiftNode('4D', newNode, null, true)
        }
      }
    },
    [type, screenToFlowPosition, nodes]
  );

  const imageWidth = 1028;
  const imageHeight = 800;


  // //To render placeholder On clicking Add Action (THEN SUBBRANCH)
  const addnodeToCanvasThen = (placeholderId, idToConnect, nodesInEdit: any = null) => {

    let newNode: any = null
    setNodes((prev) => {
      const targetNode = prev.find((node) => node.id === idToConnect)
      newNode = {
        id: placeholderId,
        type: 'placeholder',
        position: targetNode && { x: targetNode?.position.x, y: targetNode?.position.y + 180 },
        data: {
          size: 'small',
          addIcon: false,
          id: placeholderId, showCloseIcon: true
        },
      }
      const newEdge = {
        id: 'edge-3-' + placeholderId,
        source: '3',
        target: placeholderId,
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
        sourceHandle: 'bottom',
        targetHandle: 'left'
      };
      setEdges((eds: any) => [...eds, newEdge]);
      return prev
    })
    addAndShiftNode(placeholderId, newNode, null, false, 160)
  }
  //To render placeholder On clicking Add Action (ELSE SUBBRANCH)
  const addnodeToCanvasElse = (placeholderId, idToConnect, nodesInEdit: any = null) => {

    let newNode: any = null
    setNodes((prev) => {
      const targetNode = prev.find((node) => node.id === idToConnect)
      newNode = {
        id: placeholderId,
        type: 'placeholder',
        position: targetNode && { x: targetNode?.position.x, y: targetNode?.position.y + 180 },
        data: {
          size: 'small',
          addIcon: false,
          id: placeholderId, showCloseIcon: true
        },
      }
      const newEdge = {
        id: 'edge-4-' + placeholderId,
        source: '4',
        target: placeholderId,
        type: 'custom',
        markerEnd: { type: 'arrowclosed' },
        sourceHandle: 'bottom',
        targetHandle: 'left'
      };
      setEdges((eds: any) => [...eds, newEdge]);
      return prev
    })
    addAndShiftNode(placeholderId, newNode, null, false, 160)
  }

  //GENERIC FUNCITON TO AUTOMATICALLY HANDLE THE ADDING AND DOWNSHIFTING UPON ADDING ANY NODE
  const addAndShiftNode = (replaceNodeById, replacementNode, addedNode, skipShifting = false, shiftY = 120) => {
    setNodes((nds) => {
      // Step 1: Replace the node with `replaceNodeById`
      let updatedNodes = nds.map((node) =>
        node.id === replaceNodeById ? { ...node, ...replacementNode } : node
      );
      // Step 2: Add the new node if it exists and doesn't already exist in the nodes array
      if (addedNode && !nds.some((node) => node.id === addedNode?.id)) {
        updatedNodes.push(addedNode);
      }

      // Step 3: Skip shifting if `skipShifting` is true
      if (skipShifting || !replacementNode) {
        return updatedNodes;
      }

      // Step 4: Extract level and suffix from the reference node
      const referenceLevel = parseInt(replacementNode.id.match(/^\d+/)[0], 10);
      const referenceSuffix = replacementNode.id.replace(/^\d+/, ''); // Extract suffix, e.g., 'A' from '4A'

      // Step 5: Shift nodes dynamically
      updatedNodes = updatedNodes.map((node) => {
        const nodeLevel = parseInt(node.id.match(/^\d+/)[0], 10);
        const nodeSuffix = node.id.replace(/^\d+/, ''); // Extract suffix for comparison

        // Shift logic:
        // 1. Nodes at a higher level (e.g., 5-series when adding 4A).
        // 2. Nodes at the same level but alphabetically after (e.g., 4B after 4A).
        if (
          nodeLevel > referenceLevel ||
          (nodeLevel === referenceLevel && nodeSuffix > referenceSuffix)
        ) {
          return {
            ...node,
            position: {
              x: node.position.x,
              y: node.position.y + shiftY, // Adjust shift value as needed
            },
          };
        }
        return node;
      });

      return updatedNodes;
    });
  };


  const disableUpdateButton = () => {
    return isEqual(executionPlan, initialExecutionPlanRef?.current)
  }

  const disableSave = () => {
    if (executionPlan) {
      if (executionPlan.trigger.type !== null && executionPlan.trigger.type?.length === 0) return true
      if (executionPlan.action.length > 1 && executionPlan.condition == null) return true
      if (executionPlan.action[0].list.length == 0) return true
      if (executionPlan.action.length == 2 && executionPlan.action[1].list.length == 0) return true
      else return false
    }
  }

  const btnsList = useMemo(() => {
    return [
      {
        buttonText: location?.state?.mode === 'Edit' ? 'Update' : 'Save',
        buttonId: 'add',
        btnClassName: disableUpdateButton() ? 'primary__btn disabled' : 'primary__btn',
        isDisabled: disableUpdateButton(),
        handleClick: async (): Promise<any> => {
          if (disableSave()) {
            toast.error('Activity fields cant be empty in the Execution Plan');
            return
          }
          setShowLoader(true)
          reactFlowInstance.fitView({ padding: 0.1 });
          setTimeout(async () => {
            const viewportElement = document.querySelector('.react-flow__viewport');
            if (viewportElement instanceof HTMLElement) {
              const nodesBounds = getNodesBounds(reactFlowInstance.getNodes());
              const viewport = getViewportForBounds(
                nodesBounds,
                imageWidth,
                Infinity,
                0.01,
                2,
                10
              );

              const dataUrl = await toPng(viewportElement, {
                backgroundColor: '#FAFAFA',
                width: imageWidth,
                height: imageHeight,
                style: {
                  width: imageWidth.toString(),
                  height: imageHeight.toString(),
                  transform: `translate(${viewport.x}px, ${viewport.y}px) scale(0.2)`,
                },
              });

              // Convert dataUrl to Blob
              const blob = dataURLtoBlob(dataUrl);

              const file = new File([blob], 'canvasImage.png', { type: 'image/png' });

              // API call to upload the file
              handleFileChange(file)
            } else {
              console.error('Viewport element not found.');
            }
          }, 100); // Adjust delay as needed
        },
        buttonVariant: 'filled',

      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/new-rule', { state: { mode: location?.state?.mode, ruleId: location?.state?.ruleId } });
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      }
    ];
  }, [ruleEngineFormRedux, executionPlan, disableUpdateButton, reactFlowInstance, initialExecutionPlanRef?.current]);
  //Trying Save and restore
  // const instanceRef = useRef<ReactFlowInstance<any, Edge> | null>(null);
  // Function to handle instance initialization
  // const onInit = (instance: ReactFlowInstance<any, Edge>) => {
  //   instanceRef.current = instance; // Store the instance
  // };
  // const saveToLocalStorage1 = () => {
  //   if (instanceRef.current) {
  //     const flow = instanceRef.current.toObject();
  //     localStorage.setItem('example-flow', JSON.stringify(flow));
  //   }
  // };
  // const saveToLocalStorage = () => {
  //   if (instanceRef.current) {
  //     const flow = instanceRef.current.toObject();
  //     return flow;
  //   }
  // };
  // const restoreFromLocalStorage = () => {
  //   const flowString = localStorage.getItem('example-flow');
  //   if (flowString && instanceRef.current) {
  //     const flow = JSON.parse(flowString);
  //     instanceRef.current.setNodes(flow.nodes || []);
  //     instanceRef.current.setEdges(flow.edges || []);
  //     instanceRef.current.setViewport(flow.viewport);
  //   }
  // };

  //STOPPING THE REFRESH IN THE EXECUTION PLAN 

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!refreshAllowed) {
        e.preventDefault();
        e.returnValue = ''; // Required for Chrome

      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [refreshAllowed]);

  console.log(ruleEngineFormRedux, 'ruleEngineFormRedux inside')

  const handleFileChange = async (file: File | null): Promise<void> => {
    if (file && file?.name) {
      const responsePreSigned = await dispatch(fetchPreSignedURLForUpload({ fileName: file?.name }));
      if (responsePreSigned && responsePreSigned.data != null) {
        const responsePut = await axios.put(responsePreSigned?.data?.url, file, {
          headers: {
            'Content-Type': file.type,
            'x-ms-blob-type': 'BlockBlob'
          }
        }
        )
        if (responsePut?.status === 201 || responsePut?.status === 200 || responsePut?.status === 202) {
          const ruleEngineForm: IRuleEngineFormRedux = {
            id: ruleEngineFormRedux?.id ?? '',
            name: ruleEngineFormRedux?.name ?? '',
            description: ruleEngineFormRedux?.description ?? '',
            chargerIds: ruleEngineFormRedux?.chargerIds ?? '',
            status: ruleEngineFormRedux?.status ?? '',
            draftStatus: ruleEngineFormRedux?.draftStatus ?? '',
            executionPlan: executionPlan,
            fileName: responsePreSigned?.data?.blobName,
            imageURL: responsePreSigned?.data?.url,
            autoAssign: ruleEngineFormRedux?.autoAssign ?? '',
          };

          dispatch(setRuleEngineFormRedux(ruleEngineForm));
          setShowLoader(false)
          navigate('/new-rule', { state: { mode: location?.state?.mode, ruleId: location?.state?.ruleId } });
        }

      }
    }
  };

  const dataURLtoBlob = (dataUrl: string): Blob => {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };

  //To remove the react-flow watermark
  const proOptions = { hideAttribution: true };
  return (
    <>
      <DashboardLoader showLoader={showLoader} />

      <div className="dndflow">
        <Sidebar nodes={nodes} />
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onNodeDragStop={handleNodeDragStop}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onDrop={onDrop}
            onDragOver={onDragOver}
            edgeTypes={edgeTypes}
            proOptions={proOptions}
            // onInit={onInit}
            nodesDraggable={false}
            panOnDrag={true}
            elementsSelectable={true}
            // onKeyDown={handlePaneKeyDown}
            deleteKeyCode={null}
            minZoom={0.05}
          // fitView
          >
            <Controls style={{ marginBottom: '40px' }} />
          </ReactFlow>
          <div className='rule__engine__canvas__footer'>
            <CustomButtonGroup
              buttonsList={btnsList}
              buttonGroupClassName='button__group__footer'
            />
          </div>
        </div>
      </div>
    </>
  );
};
export const ExecutionPlan: React.FC = () => {
  const [levelOfHierarchy, setLevelOfHeirarchy] = useState('trigger');
  return (
    <>
      <ReactFlowProvider>
        <DnDProvider>
          <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <CustomBreadCrumbs breadCrumbs={breadCrumbsFunction()} levelOfHeirarchy={levelOfHierarchy} customRuleEngine={true} />
            <DnDFlow setLevelOfHeirarchy={setLevelOfHeirarchy} />
          </div>
        </DnDProvider>
      </ReactFlowProvider>
    </>
  );
}
export default ExecutionPlan;
