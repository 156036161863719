export const FETCH_CUSTOMER_REFERENCE_DATA = 'FETCH_CUSTOMER_REFERENCE_DATA';
export const FETCH_CHARGER_TYPE_REFERENCE_DATA = 'FETCH_CHARGER_TYPE_REFERENCE_DATA';
export const FETCH_REGION_REFERENCE_DATA = 'FETCH_REGION_REFERENCE_DATA';
export const FETCH_SOFTWARE_VERSION_REFERENCE_DATA = 'FETCH_SOFTWARE_VERSION_REFERENCE_DATA';
export const FETCH_CHARGER_STATUS_REFERENCE_DATA = 'FETCH_CHARGER_STATUS_REFERENCE_DATA';
export const FETCH_ALARM_SEVERITY_REFERENCE_DATA = 'FETCH_ALARM_SEVERITY_REFERENCE_DATA';
export const FETCH_CHARGER_MODEL_REFERENCE_DATA = 'FETCH_CHARGER_MODEL_REFERENCE_DATA';
export const FETCH_CHARGER_STATION_REFERENCE_DATA = 'FETCH_CHARGER_STATION_REFERENCE_DATA';
export const FETCH_HISTORY_TYPE_REFERENCE_DATA = 'FETCH_HISTORY_TYPE_REFERENCE_DATA';
export const FETCH_HISTORY_INTERVAL_REFERENCE_DATA = 'FETCH_HISTORY_INTERVAL_REFERENCE_DATA'
export const FETCH_CHARGER_LOCATION_REFERENCE_DATA = 'FETCH_CHARGER_LOCATION_REFERENCE_DATA';
export const FETCH_ALARM_TYPE_REFERENCE_DATA = 'FETCH_ALARM_TYPE_REFERENCE_DATA'
export const FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA = 'FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA'
export const FETCH_USER_ROLE_TYPE_REFERENCE_DATA = 'FETCH_USER_ROLE_TYPE_REFERENCE_DATA'
export const FETCH_USER_USER_TYPE_REFERENCE_DATA = 'FETCH_USER_USER_TYPE_REFERENCE_DATA'
export const FETCH_PARAMETERS_REFERENCE_DATA = 'FETCH_PARAMETERS_REFERENCE_DATA';
export const FETCH_USERS_REFERENCE_DATA = 'FETCH_USERS_REFERENCE_DATA ';
export const FETCH_ORG_REFERENCE_DATA = 'FETCH_ORG_REFERENCE_DATA'
export const FETCH_ORG_TYPE_REFERENCE_DATA = 'FETCH_ORG_TYPE_REFERENCE_DATA'
export const FETCH_ROLE_PERMISSION_DATA = 'FETCH_ROLE_PERMISSION_DATA '
export const FETCH_CHARGER_VISIBILITY_REFERENCE_DATA = 'FETCH_CHARGER_VISIBILITY_REFERENCE_DATA';
export const FETCH_EXCEPTION_LOGS_SEVERITY_REFERENCE_DATA = 'FETCH_EXCEPTION_LOGS_SEVERITY_REFERENCE_DATA';
export const FETCH_ALLOWED_COMMANDS_FOR_ROLE_REFERENCE_DATA = 'FETCH_ALLOWED_COMMANDS_FOR_ROLE_REFERENCE_DATA';
export const FETCH_CHARGER_PRODUCT_TYPE_REFERENCE_DATA = 'FETCH_CHARGER_PRODUCT_TYPE_REFERENCE_DATA '
export const FETCH_RULE_ALARM_TYPES = 'FETCH_RULE_ALARM_TYPES'
export const FETCH_RULE_ALARM_TYPES_EXECUTION_PLAN = 'FETCH_RULE_ALARM_TYPES_EXECUTION_PLAN'
export const FETCH_RULE_TYPE_OF_NOTIFICATION = 'FETCH_RULE_TYPE_OF_NOTIFICATION'
export const FETCH_RULE_ID_CONFIGURE_NOTIFICATION_HISTORY = 'FETCH_RULE_ID_CONFIGURE_NOTIFICATION_HISTORY'

export const FETCH_RULE_ENGINE_TRIGGER_CATEGORY_REFERENCE_DATA = 'FETCH_RULE_ENGINE_TRIGGER_CATEGORY_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_TRIGGER_LEVEL_REFERENCE_DATA = 'FETCH_RULE_ENGINE_TRIGGER_LEVEL_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_ACTION_CONTROL_REFERENCE_DATA = 'FETCH_RULE_ENGINE_ACTION_CONTROL_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_SCHEDULE_REFERENCE_DATA = 'FETCH_RULE_ENGINE_SCHEDULE_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_STATUS_REFERENCE_DATA = 'FETCH_RULE_ENGINE_STATUS_REFERENCE_DATA'
export const FETCH_TRIGGER_POINT_PARAMETER_CONDITION_REFERENCE_DATA = 'FETCH_TRIGGER_POINT_PARAMETER_CONDITION_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_PARAM_CONDITION = 'FETCH_RULE_ENGINE_PARAM_CONDITION'
export const FETCH_RULE_ENGINE_PARAM_OPERATOR = 'FETCH_RULE_ENGINE_PARAM_OPERATOR'
export const FETCH_RULE_ENGINE_ACTION_CONTROL_SEND_NOTIFICATION_RECIPIENT = 'FETCH_RULE_ENGINE_ACTION_CONTROL_SEND_NOTIFICATION_RECIPIENT'
export const FETCH_RULE_ENGINE_RAISE_A_TICKET_CATEGORY = 'FETCH_RULE_ENGINE_RAISE_A_TICKET_CATEGORY'
export const FETCH_RULE_ENGINE_RAISE_A_TICKET_URGENCY = 'FETCH_RULE_ENGINE_RAISE_A_TICKET_URGENCY'
export const FETCH_RULE_ENGINE_RAISE_A_TICKET_CALL_TYPE = 'FETCH_RULE_ENGINE_RAISE_A_TICKET_CALL_TYPE';


export const FETCH_RULE_ENGINE_RULE_NAME_REFERENCE_DATA = 'FETCH_RULE_ENGINE_RULE_NAME_REFERENCE_DATA'
export const FETCH_RULE_ENGINE_SET_PARAMETER_PARAM = 'FETCH_RULE_ENGINE_SET_PARAMETER_PARAM'
export const FETCH_RULE_ENGINE_REMOTE_COMMANDS = 'FETCH_RULE_ENGINE_REMOTE_COMMANDS'
export const FETCH_ALARM_RULE_SEVERITY = 'FETCH_ALARM_RULE_SEVERITY'
export const FETCH_CHARGER_ACTIVE_CERTIFICATE_REFERENCE_DATA='FETCH_CHARGER_ACTIVE_CERTIFICATE_REFERENCE_DATA';
