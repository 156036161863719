/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Handle, Position } from '@xyflow/react';
import { SidePopover } from '../../../globalUtils/globalExports';
import SetParameterSidePopOver from './SetParameterSidePopOver';
import './SetParameter.css'
import CloseIcon from '@mui/icons-material/Close';
import ExecutionPlan, { SetParameterAction, SetParameters } from './ExecutionPlan';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
interface CustomNodeProps {
    data: {
        actionButtonVisible:string,
        addNodeToCanvas,
        addIcon: boolean;
        executionPlan: ExecutionPlan;
        handleUpdateExecutionPlan: Function;
        ifOrElse?: string;
       removeNode?: Function
      id?: String
      typeOfNode:string
      }
  }

const SetParameterNode: React.FC<CustomNodeProps> = ({ data }: CustomNodeProps) => {

  const [isSetParameterPopOverOpen, setIsSetParameterPopOverOpen] = useState(false);
    const handleEditClick = () => {
    setIsSetParameterPopOverOpen(true)
  };
  
  const [setParameters, setSetParamaeters] = useState<SetParameters[]| null>(null);


useEffect(()=>{
  const currentSetParameters = (data?.executionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Set Parameter') as SetParameterAction)
  
    setSetParamaeters(currentSetParameters?.parameters)
},[ (data?.executionPlan?.action.find((executionPlanItem) => executionPlanItem.flowType === data?.ifOrElse)?.list?.find((executionPlanAction) => executionPlanAction.type === 'Set Parameter') as SetParameterAction)])

  const handleCloseSetParameterPopover = () : void => {
    setIsSetParameterPopOverOpen(false)
  }

    return (
      <>
   <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' ,justifyContent:'center'}}>
   
   <Paper
  elevation={3}
  sx={{
    border: '1px solid #0080DC',
    borderLeft: '6px solid #0080DC',
    borderRadius: '8px',
    padding: '8px 16px',
    minWidth: '300px !important',
    backgroundColor: '#fff',
    position: 'relative',
    marginBottom: '16px',
    minHeight:'110px'

  }}
   >
  <IconButton
    size="small"
    onClick={() => {
      if (data?.removeNode) {  
        data.removeNode(data?.id,data?.ifOrElse,data?.typeOfNode);   
      } 
    }}
    sx={{ position: 'absolute', top: 0, right: 0 }}
     >
    <CloseIcon fontSize="small" sx={{ color: '#666' }} />
  </IconButton>
  <Handle type="target" className='transparent-handle' position={Position.Left} id='left' />
  <Box 
    display="flex" 
    alignItems="center" 
    justifyContent="space-between" 
    marginBottom="8px"
  >
    {/* Title with Notification Icon */}
    <Box display="flex" alignItems="center">
    <IconWithTooltip iconClassName={'set-parameter-sidebar-1'} />
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          fontSize: '1rem',
          marginBottom:'1px'
        }}
      >
        {'Set Parameter'}
      </Typography>
    </Box>
  </Box>

  {/* Category and Subcategory */}
  <Box
    sx={{
      backgroundColor: '#f0f4ff',
      padding: '8px',
      borderRadius: '4px',
      position: 'relative',
      display:'flex'
    }}
  >
    {/* Set Parameter Items */}
   <div style={{ display: 'flex', flexDirection:'row',justifyContent:'space-between',width:'100%'}}> 
         <div style={{display: 'flex', alignItems: 'center'}}>         
         <span
          style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#0080DC',
            borderRadius: '50%',
            display: 'inline-block',
          }}
           />   
         {!setParameters &&
         
        <Typography
        variant="body2"
        sx={{ fontSize: '0.875rem', color: '#666', display: 'flex', alignItems: 'center', marginLeft:'5px' }}
      >
        Set()
      </Typography>
        }         
          </div>
                
        <Box display="flex" flexDirection="column" alignItems="left" justifyContent='space-between' sx={{margin:'auto 0 auto 5px'}}>
        {setParameters &&
          setParameters.map((item, index) => {
            return (
              <Box
          key={index}
            sx={{
            display: 'block', 
            width: '100%',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.875rem',
              color: '#333',
              lineHeight: 1.5, 
            }}
          >
                {index === 0? <span className='rule__engine__define__execution__selected__parameters'>{'SET('}</span> : <span>{ item.parameterOperator && item.parameterOperator.toLowerCase()+'('}</span>}
              <span className='rule__engine__define__execution__selected__parameters'>{item.parameterName}</span>{' '}
                  <span >
                  
                  {item.parameterConditionName?.toLocaleLowerCase()}{' '}
                  </span>
              {item.parameterValue.length > 0 &&  (
                <span className='rule__engine__define__execution__selected__parameters'>{item.parameterValue?.join(',')}</span>)}{' '}
              {item.unit !== null && item.unit !== 'NA' && <span className='rule__engine__define__execution__selected__parameters'>{item?.unit?.slice(0,3)}</span>}{')'}
          </Typography>
        </Box>
             );
        })}
       </Box>
    <IconButton
        size="small"
        onClick={handleEditClick}
        sx={{
          ':hover': {
            backgroundColor: 'transparent', 
          },
        }}
    >
      <EditIcon fontSize="small" sx={{ color: '#666' }} />
    </IconButton>   
    </div>                 
  </Box>
</Paper>
    {data.actionButtonVisible && 
        <Typography
        onClick={() => data.addNodeToCanvas()}
        variant="body2"
        sx={{
          color: '#B0B1B6',
          marginTop: '8px',
          marginLeft: '10px',
          cursor: 'pointer',
          fontSize: '0.875rem',
        }}
      >
        + Add Activity
        </Typography>
      }
      </div>
      {isSetParameterPopOverOpen && <SidePopover
         id='rule__engine__execution__plan__if__then__else__side__popOver'
         open={isSetParameterPopOverOpen}
         headerText={`Action Control - ${data.ifOrElse === 'If' ? 'Then' : 'Else'}`}
         headerSecondaryText={'Set Parameter'}
         horizontalLinePopover={true}
         containerClassName='configure__notification__detail__popover'
         handleClose={()=>{}}
         popperClassName='rule__engine__set__parameter'
         headerClassName={'rule__engine__header__config'}
         subHeadingIconClassName={'set-parameter-sidebar'}
         isRuleEnginePopOver={true}
         ruleEngineSidePopoverOnclose={handleCloseSetParameterPopover}
         // eslint-disable-next-line react/no-children-prop
         children={
            <>
              {<SetParameterSidePopOver 
                  setParameters = {setParameters ?? null}
                  executionPlan = {data?.executionPlan}
                  handleUpdateExecutionPlan = {data?.handleUpdateExecutionPlan}
                  handleClose={handleCloseSetParameterPopover} 
                  ifOrElse={data?.ifOrElse?? ''}
                  
              />}
            </>
        } 
         />
       }

      </>

  );
};

export default SetParameterNode;

