

export const ruleEnginePopOverNavList = [
  {
    id: 'ruleEngine',
    text: 'Rules',
    isAccessible: true
  },
  {
    id: 'ruleEngineHistory',
    text: 'History',
    isAccessible: true
  },
]

export const ruleEngineStatusMapper = {
  Running: {
    title: 'Running',
    icon: 'rule_engine_running_status_icon'
  },
  Stopped: {
    title: 'Stopped',
    icon: 'rule_engine_stopped_status_icon '
  },
  Error: {
    title: 'Error',
    icon: 'rule_engine_error_status_icon'
  },
  Draft: {
    title: 'Draft',
    icon: 'rule_engine_draft_status_icon '
  }
}